import React, { ReactElement, useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button, Stack } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router';
import { MandateTable } from '../../../mandates/listing/table';
import { FundTable } from '../../../funds/listing/table';
import { ApiResponse, Fund, Mandate, Nullable } from '../../../../types/types';
import { FundAndMandateProvider } from '../../../../services/data';
import ServiceError from '../../../../services/errors';
import ErrorPageManager from '../../../../components/Error/errorpagemanager';
import checkRoles from '../../../../components/authentification/checkRoles';

function FundAndMandateList(props: {dataProvider: FundAndMandateProvider, entityId: string, entityTypeText: string}): ReactElement {
  const keycloak = useKeycloak();
  const isAdmin = checkRoles(['admin'], keycloak.keycloak);
  const navigate = useNavigate();
  const [fundListResp, setFundListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Fund>>>>>(null);
  const [mandatesListResp, setMandatesListResp] = React.useState<Nullable<ApiResponse<Nullable<Array<Mandate>>>>>(null);
  const [fundsError, setFundsError] = useState<Nullable<ServiceError>>(null);
  const [mandatesError, setMandatesError] = useState<Nullable<ServiceError>>(null);

  useEffect(() => {
    props.dataProvider.getFunds(props.entityId)
      .then((apiResp) => {
        setFundListResp(apiResp);
      })
      .catch((err) => {
        setFundsError(ServiceError.ensureServiceError(err));
      });
    props.dataProvider.getMandates(props.entityId)
      .then((apiResp) => {
        setMandatesListResp(apiResp);
      })
      .catch((err) => {
        setMandatesError(ServiceError.ensureServiceError(err));
      });
  }, []);

  return (
    <>
      <Stack height='50px' direction='row' display='flex' alignItems='center' spacing={2}>
        <h3>Mandates on this {props.entityTypeText}</h3>
        {isAdmin && props.entityTypeText === 'strategy' &&
              <Button
                size='small'
                variant='outlined'
                color='primary'
                onClick={() => {
                  navigate(`/admin/addMandateToStrategy/${props.entityId}`);
                }}
                startIcon={<AddRoundedIcon />}
                sx={{
                  minWidth: '165px',
                  maxHeight: '30px'
                }}>
                  Add Mandate
              </Button>
        }
      </Stack>
      {mandatesError ? <ErrorPageManager error={mandatesError} /> :
        <div style={{ marginBottom: '60px' }}>
          { (mandatesListResp != null) && (mandatesListResp.data != null) &&
            <>
              { (mandatesListResp.data.length === 0) &&
                <div>No Mandates found...</div>
              }
              { (mandatesListResp.data.length > 0) &&
                <MandateTable list={mandatesListResp.data} />
              }
            </>
          }
        </div>
      }
      <Stack height='50px' direction='row' display='flex' alignItems='center' spacing={2}>
        <h3>Funds on this {props.entityTypeText}</h3>
        {isAdmin && props.entityTypeText === 'strategy' &&
            <Button
              size='small'
              variant='outlined'
              color='primary'
              onClick={() => {
                navigate(`/admin/addFundsToStrategy/${props.entityId}`);
              }}
              startIcon={<AddRoundedIcon />}
              sx={{
                minWidth: '165px',
                maxHeight: '30px'
              }}>
                Add Fund
            </Button>
        }

      </Stack>
      {fundsError ? <ErrorPageManager error={fundsError} /> :
        <>
          { (fundListResp != null) && (fundListResp.data != null) &&
            <>
              { (fundListResp.data.length === 0) &&
                <div>No Funds found...</div>
              }
              { (fundListResp.data.length > 0) &&
                <FundTable list={fundListResp.data} />
              }
            </>
          }
        </>
      }
      {isAdmin && props.entityTypeText === 'asset manager' &&
        <Button
          size='small'
          variant='outlined'
          color='primary'
          onClick={() => {
            navigate(`/admin/addStrategyToAM/${props.entityId}`);
          }}
          startIcon={<AddRoundedIcon />}
          sx={{
            minWidth: '165px',
            maxHeight: '50px',
            marginTop: '10px',
            marginBottom: '30px'
          }}>
            Add Strategy
        </Button>
      }
    </>
  );
}

export default FundAndMandateList;

import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IframeResizer } from "@open-iframe-resizer/react";
import { Paper, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Link, useLocation, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { ReportHelper } from './pdfreports';
import { DdReport, Nullable } from '../../types/types';
import { mainRadius } from '../../themes/theme';
import CustomButton, { CustomIconButton } from '../button/button';
import ServiceError from '../../services/errors';
import { DdReportService } from '../../services/ddreports';
import ErrorPageManager from '../Error/errorpagemanager';
import AccessTokenService from '../../services/accesstoken';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: 'white',
    borderRadius: mainRadius,
    display: 'flex',
    padding: '6px',
    height: '50px'
  },
  iFrame: {
    padding: '24px 6px',
    minWidth: '100%',
    height: 'min-content',
    marginTop: '24px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: mainRadius
  }
}));

function DdSummaryReport(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const accessTokenService = new AccessTokenService();

  const params = useParams<{ id: string }>();
  const reportId = params.id;

  const [report, setReport] = useState<Nullable<DdReport>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [accessToken, setAccessToken] = useState<string>();

  const ddReportService = new DdReportService();

  const updateData = () => {
    if (reportId) {
      ddReportService.getEntity(reportId)
        .then((apiResp) => {
          setReport(apiResp.data);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
        });
    }
  };

  const getAccessToken = (id: string) => {
    accessTokenService.get(id)
      .then(res => setAccessToken(res.data?.token));
  };

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    if (report?.ddInfo.ddId) {
      getAccessToken(report.ddInfo.ddId);
    }
  }, [report]);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        <>
          { report && reportId && <>
            <Paper variant='elevation' elevation={0} className={classes.header}>
              <Link to={`${pathname.split('/').slice(0, -1).join('/')}`}>
                <CustomIconButton variant='outlined' small>
                  <KeyboardBackspaceIcon />
                </CustomIconButton>
              </Link>
              <Typography variant='h6' style={{ marginLeft: '12px', padding: '4px 0px' }}>{report.title}</Typography>
              <CustomButton variant='contained' style={{ marginLeft: 'auto' }} small onClick={() => ReportHelper.downloadPDFReportByDdReportId(reportId)}>
                <GetAppOutlinedIcon />
                <Typography style={{ paddingLeft: '6px' }}>{t('dds.tabs.reports.downloadPDF')}</Typography>
              </CustomButton>
            </Paper>
            {accessToken &&
              <Paper variant='elevation' elevation={0} className={classes.iFrame}>
                <IframeResizer
                  // log
                  src={ReportHelper.getHTMLReportURL(report.ddInfo.ddId, report, accessToken)}
                  style={{ minWidth: '100%', border: 'none' }}
                />
              </Paper>
            }
          </>
          }
        </>
      }
    </>
  );
}

export default DdSummaryReport;

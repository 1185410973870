import React, { ReactElement, useEffect, useState } from 'react';
import { LinearProgress, Snackbar, Alert, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Nullable, Settings } from '../types/types';
import { PlatformSettingsService } from '../services/platform';
import ServiceError from '../services/errors';
import { DataContextSettings } from '../hooks/datatabs';
import CodePage from '../components/maintenance/codepage';
import { API } from '../api/api';
import { i18nPossibleLanguages } from '../../i18n/config';
import { API_AUM_CONNECTOR_BASE_URL, API_BASE_URL } from '../utils/env';

const useStyles = makeStyles({
  progressBar: {
    '&[aria-valuenow="50"]': {
      "& > $progressBarInner": {
        transition: "none"
      }
    }
  },
  progressBarInner: {},
  callProgressBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100vw',
    height: '4px'
  }
});

function SettingsWrapper(props: { authenticated: boolean, nonce: string, children: ReactElement }): ReactElement {
  const classes = useStyles();
  const [settings, setSettings] = useState<Nullable<Settings>>(null);
  const [updateSettings, setUpdateSettings] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [ready, setReady] = useState(false);
  const [serverCode, setServerCode] = useState<Nullable<number>>(null);
  const platformSettingsService = new PlatformSettingsService();
  const [startDate, setStartDate] = useState<Nullable<Date>>(null);
  const [progress, setProgress] = useState(props.authenticated ? 50 : 0);
  const [callProgress, setCallProgress] = useState(0);
  const [callState, setCallState] = useState<Nullable<'sent' | 'received'>>();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/keycloak.json`)
      .then(async (res) => {
        const resJson = await res.json();
        const csp = `
          default-src 'self';
          connect-src 'self' ${resJson['auth-server-url']} ${API_BASE_URL}/api/v1/ ${API_AUM_CONNECTOR_BASE_URL}/;
          script-src 'self' ${API_BASE_URL} ${API_BASE_URL.replace('/deeligenz-server', '/deeligenz/static/media/')} ${API_AUM_CONNECTOR_BASE_URL};
          style-src 'unsafe-inline';
          object-src 'none';
          img-src 'self' ${API_BASE_URL}/api/v1/;
          frame-src 'self';
        `;

        const metaTag = document.createElement('meta');
        metaTag.httpEquiv = 'Content-Security-Policy';
        metaTag.content = csp;
        document.head.appendChild(metaTag);
      });
  }, []);

  useEffect(() => {
    API.defaults.headers.common[
      "Accept-Language"
    ] = `${i18nPossibleLanguages[0].version},${i18nPossibleLanguages[0].global};`;

    API.interceptors.request.use((config) => {
      setCallState('sent');

      return config;
    }, (error => Promise.reject(error)));

    API.interceptors.response.use((res) => {
      const maintenance = res.headers?.['x-deecision-maintenance'];

      setCallState('received');
      if (maintenance) {
        if (new Date(maintenance).toISOString() !== startDate?.toISOString()) {
          setStartDate(new Date(maintenance));
        }
      } else {
        setStartDate(null);
      }

      return res;
    }, (error) => {
      setCallState('received');

      if (error.response && error.response.status === 503) {
        setServerCode(503);
        if (window.location.pathname.split('/')[2] !== 'maintenance') {
          window.location.assign(`${window.location.origin}/deeligenz/maintenance`);
        }
      } else if (error.response && error.response.status > 500) {
        setServerCode(500);
        if (window.location.pathname.split('/')[2] !== 'maintenance') {
          window.location.assign(`${window.location.origin}/deeligenz/maintenance`);
        }
      }

      return Promise.reject(error);
    });
  }, []);

  useEffect(() => {
    if (callState === 'sent' && callProgress < 100) {
      setTimeout(() => {
        if (callProgress < 1) {
          setCallProgress(1);
        } else {
          setCallProgress(old => old + 5);
        }
      }, 10);
    } else if (callState === 'received') {
      setTimeout(() => {
        if (callProgress < 100) {
          setCallProgress(100);
        } else {
          setCallState(null);
          setCallProgress(0);
        }
      }, 200);
    } else if (!callState) {
      setCallProgress(0);
    }
  }, [callState, callProgress]);

  useEffect(() => {
    if (props.authenticated && updateSettings) {
      setProgress(80);
      setUpdateSettings(false);
      platformSettingsService.getSettings()
        .then((apiResp) => {
          setProgress(100);
          if (apiResp.data) {
            setSettings(apiResp.data);
          }
        })
        .catch((err) => {
          if (err.response) {
            const error = ServiceError.ensureServiceError(err);

            if (error.httpStatus !== 503) {
              setErrorMsg(ServiceError.getErrorMsg(err));
              setSnackbarOpen(true);

            }
          }
          setReady(true);
        });
    } else  {
      setProgress(50);
      setReady(true);
    }
  }, [updateSettings, props.authenticated]);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    }
  }, [progress]);

  return (
    <>
      {props.authenticated && ready && !window.location.pathname.includes('maintenance') ?
        <>
          {callProgress > 0 &&
            <Box className={classes.callProgressBar}>
              <LinearProgress
                variant='determinate'
                value={callProgress}
              />
            </Box>
          }
          <DataContextSettings value={{ settings, maintenance: { programmed: !!startDate, start: startDate, end: null }, setUpdateSettings }}>
            {props.children}
          </DataContextSettings>
        </>
        :
        window.location.pathname.includes('maintenance') ?
          <CodePage code={serverCode} />
          :
          <LinearProgress
            className={props.authenticated ? classes.progressBar : undefined}
            variant='determinate'
            value={progress}
            classes={props.authenticated ? { bar: classes.progressBarInner } : undefined}
          />
      }
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SettingsWrapper;

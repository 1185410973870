import React, { ReactElement, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import Router from './router/main';
import '../index.css';
import SettingsWrapper from './settings/settingswrapper';

function Deeligenz(): ReactElement {
  const keycloak = useKeycloak();
  const [authenticated, setAuthenticated] = useState(false);
  const nonce = Buffer.from(uuidv4()).toString('base64');

  useEffect(() => {
    keycloak.keycloak.onReady = () => {
      setAuthenticated(true);
    };
  }, [keycloak]);

  return (
    <SettingsWrapper authenticated={authenticated} nonce={nonce}>
      <Router />
    </SettingsWrapper>
  );
}

export default Deeligenz;

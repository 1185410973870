import React, { ReactElement } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Editor from 'rich-markdown-editor';
import ReactKeyUtils from '../../../utils/reactkeyutils';
import { light } from '../customEditorTheme';

const useStyles = makeStyles(() => ({
  editor: {
    margin: 'auto'
  },
  fieldContent: {
    paddingRight: '16px',
    maxWidth: 'calc(100% - 54px)',
    flexBasis: 'calc(100% - 54px)',
    '& p': {
      margin: '0',
      padding: '0',
      fontSize: '14px'
    }
  }
}));

interface VerticalSplittedComparisonProps {
	previousValue?: string,
	currentValue?: string
}

export default function VerticalSplittedComparison (props: VerticalSplittedComparisonProps): ReactElement {
  const { previousValue, currentValue } = props;
  const classes = useStyles();
  const myKey = ReactKeyUtils.generateRandomKey();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h3'>
              Previous Value :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Editor
              key={myKey.toString()}
              className={ classes.editor }
              defaultValue={ previousValue || '' }
              readOnly
              theme={light}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h3'>
              Current Value :
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Editor
              key={myKey.toString()}
              className={ classes.editor }
              defaultValue={ currentValue || '' }
              readOnly
              theme={light}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Snackbar, Typography, Alert } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DOMPurify from 'dompurify';
import QuestionService from '../../services/questions';
import TextFieldEditor from '../field/editors/textfieldeditor';
import { DdElement } from '../../types/types';
import { CustomIconButton } from '../button/button';
import ServiceError from '../../services/errors';

interface OtherRemarks {
  [key: string] : string | undefined
}

function Remarks(props: {ddElement: DdElement, questionService: QuestionService, setUpdate: Dispatch<React.SetStateAction<boolean>>, readonly?: boolean, small?: true}): ReactElement {
  const { t } = useTranslation();
  const [otherRemarks, setOtherRemarks] = useState<OtherRemarks[]>(
    [{ 'meetingRemark': props.ddElement.evaluation?.meeting?.remarks }]
  );
  const [remarks, setRemarks] = useState(props.ddElement.remarks || '');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setOtherRemarks([{ 'meetingRemark': props.ddElement.evaluation?.meeting?.remarks }]);
  }, [props.ddElement]);

  const handleSend = () => {
    props.questionService.setRemarks(props.ddElement._id, remarks)
      .then(() => {
        props.setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(ServiceError.getErrorMsg(err));
        setSnackbarOpen(true);
      });
  };

  const handleCancel = () => {
    setRemarks(props.ddElement.remarks || '');
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        {otherRemarks.map(otherRemark => (
          Object.keys(otherRemark).map((key) => {
            if (otherRemark[key] !== null && otherRemark[key] !== undefined) {
              return (
                <Grid key={key} item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h3'>{key === 'meetingRemark' ? 'Evaluation Meeting Remark :' : ''}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>{otherRemark[key]}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }

            return undefined;
          })
        ))}
        <Grid item xs={12}>
          <Typography variant='h3'>{t('ddElements.tabs.remarks.otherRemark')} :</Typography>
        </Grid>
        <Grid item xs style={{ marginBottom: '-4px', width: 'calc(100% - 116px)' }}>
          { props.readonly ?
            DOMPurify.sanitize(remarks || '')
            :
            <TextFieldEditor {...props} value={remarks} setValue={value => setRemarks(value as string)} />
          }
        </Grid>
        {!props.readonly &&
          <Grid item paddingLeft={props.small ? '4px !important' : undefined}>
            <Grid container spacing={0} alignItems='center'>
              <Grid item>
                <CustomIconButton variant='text' onClick={handleSend} disabled={remarks === props.ddElement.remarks} small={props.small}>
                  <SaveRoundedIcon fontSize={props.small ? 'small' : undefined} />
                </CustomIconButton>
              </Grid>
              <Grid item>
                <CustomIconButton
                  variant='text'
                  onClick={handleCancel}
                  color='secondary'
                  disabled={remarks === props.ddElement.remarks}
                  small={props.small}
                >
                  <ClearRoundedIcon fontSize={props.small ? 'small' : undefined} />
                </CustomIconButton>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Remarks;

import React, { ReactElement, useEffect, useState } from "react";
import { Autocomplete, Box, Button, FormControl, Input, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Fund, Strategy } from "@deecision/deeligenz-interfaces";
import { StrategyService } from "../../services/strategy";
import { MandateService } from "../../services/mandate";
import { FundService } from "../../services/fund";

interface AddMandateFormInterface {
  strategyIdAssociated: string,
  name: string,
  originalFund?: string | undefined,
  mandateFund?: string | undefined,
  dataReady: boolean
}

function AddMandateToStrategy(): ReactElement {
  const navigate = useNavigate();
  const strategyService = new StrategyService();
  const fundService = new FundService();
  const mandateService = new MandateService();
  const associatedStrategyId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  const [strategyData, setStrategyData] = useState<Strategy>();
  const [fundsLinkedToStrategy, setFundsLinkedtoStrategy] = useState<Fund[]>([]);
  const [allFunds, setAllFunds] = useState<Fund[]>([]);
  const [addMandateForm, setAddMandateForm] = useState<AddMandateFormInterface>({
    strategyIdAssociated: associatedStrategyId,
    name: '',
    originalFund: undefined,
    mandateFund: undefined,
    dataReady: false
  });

  // Refacto this ?
  const handleInputChangeByProperty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, property: string) => {
    setAddMandateForm((prevState) => {
      const isReady = prevState.strategyIdAssociated.length > 0 &&
        (
          prevState.name?.length > 0 ||
          (property === 'name' && event.target.value.length > 0)
        );
  
      return ({
        ...prevState,
        [property]: event.target.value,
        dataReady: isReady // Set dataReady to enable the create button when all properties have been filled.
      });
    });
  };

  const onCreateMandate = async () => {
    const createdMandate = await mandateService.createMandate(
      addMandateForm.strategyIdAssociated,
      addMandateForm.name,
      addMandateForm.originalFund,
      addMandateForm.mandateFund
    );

    navigate(`/mandates/${createdMandate.data?._id}/dds`);
  };

  useEffect(() => {
    strategyService.getEntity(associatedStrategyId)
      .then((res) => {
        if (res.data) {
          setStrategyData(res.data);
        }
      });
    // for originalFund List ==> parentStrategy's fund only
    strategyService.getFunds(associatedStrategyId)
      .then((res) => {
        if (res.data) {
          setFundsLinkedtoStrategy(res.data);
        }
      });
    // for mandateFund List ==> all funds possible
    fundService.getEntityList(10000)
      .then((res) => {
        if (res.data) {
          setAllFunds(res.data);
        }
      });
  }, []);

  return (
    <Stack spacing={2} pb={8}>
      <h2>Create Mandate And Link To {strategyData?.name}</h2>

      <>
        <h3>Mandate name</h3>
        <Box width='430px'>
          <Input
            onChange={(event) => {
              handleInputChangeByProperty(event, 'name');
            }}
            value={addMandateForm.name}
            placeholder='Enter Mandate Name...'
            fullWidth
          />
        </Box>
      </>

      <>
        <h3>originalFund (optional)</h3>
        <Box width='430px'>
          <Select
            variant='standard'
            value={addMandateForm.originalFund}
            onChange={(event) => {
              handleInputChangeByProperty(event, 'originalFund');
            }}
            style={{ minWidth: '430px' }}
          >
            {fundsLinkedToStrategy.map(fund =>
              <MenuItem value={fund._id}>{fund.name}</MenuItem>)
            }
          </Select>
        </Box>
      </>

      <>
        <h3>mandateFund (optional)</h3>
        <Box width='430px' py={1}>
          <FormControl fullWidth>
            <Autocomplete
              options={allFunds.map(fundName => fundName.name).filter((item, index) => allFunds.map(fundName => fundName.name).indexOf(item) === index)}
              renderInput={params => <TextField {...params} label='Select mandate fund' />}
              onChange={(event, value) => {
                const selectedMandateFund = allFunds.find(fund => fund.name === value) || undefined;
                setAddMandateForm(prevState => ({
                  ...prevState,
                  mandateFund: selectedMandateFund?._id
                }));
              }}
            />
          </FormControl>
        </Box>
      </>

      <Button
        size='small'
        variant='outlined'
        color='primary'
        onClick={onCreateMandate}
        disabled={!addMandateForm.dataReady}
        sx={{
          minWidth: '215px',
          maxWidth: '430px'
        }}>
          Create Mandate
      </Button>

    </Stack>
  );
}

export default AddMandateToStrategy;
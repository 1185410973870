import { API } from '../api/api';
import { ApiResponse, Mandate, Nullable } from '../types/types';
import { BaseEntityService } from './data';

export class MandateService extends BaseEntityService<Mandate> {
  constructor() {
    super();
  }

  getBaseUrl() : string {
    return '/api/v1/mandates';
  }

  async createMandate(strategyIdAssociated: string, name: string, originalFund?: string, mandateFund?: string):  Promise<ApiResponse<Nullable<Mandate>>> {
    const params = {
      strategyIdAssociated,
      name,
      originalFund,
      mandateFund
    };
    
    return API.post(`${this.getBaseUrl()}/createMandate`, params)
      .then(res => this.handleResponse<Nullable<Mandate>>(res));
  }
}

export default MandateService;

import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, Route, useParams, Routes, Outlet } from 'react-router-dom';
// import ImportAdminPage from './import/importadminpage';
import { Grid } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { AumImport } from '@deecision/deeligenz-interfaces/dist/types/importtypes';
import AdminPage from './adminpage';
import IdRouter from '../../router/idrouter';
import { ImportListingPage, ImportPage } from './import/importlist';
import {
  DdElementGroupsService,
  DdReportBlocksService,
  DdTemplatesService,
  ImportGroupService,
  ImportService
} from '../../services/import';
import { DdElementGroupType, Import, ImportGroup } from '../../types/importtypes';
import { DdReportBlock, DdTemplate, Nullable } from '../../types/types';
import { ImportGroupList, ImportGroupPage } from './import/importgrouplist';
import ServiceError from '../../services/errors';
import ErrorPageManager from '../Error/errorpagemanager';
import DdElementGroupHeader from './ddelementgroup/ddelementgroupheader';
import { DdElementGroupProvider, DdTemplateProvider, DdReportBlockProvider, SetUpdateHookContextProvider, DdAumProvider } from '../../hooks/datatabs';
import DisplayTabs from '../layout/tabs/tabs';
import { aumTabs, ddElementGroupTabs, ddReportBlockTabs, ddTemplateTabs } from '../../utils/tabs';
import DdTemplates from './ddtemplates/ddtemplates';
import DdTemplateHeader from './ddtemplates/ddtemplateheader';
import DdReportBlockHeader from './ddtreportblocks/ddreportblockheader';
import DdReportBlocks from './ddtreportblocks/ddreportblocks';
import SettingsPage from './settings/settingspage';
import DdElementGroups from './ddelementgroup/ddelementgroups';
import TabsRouter from '../../router/tabsrouter';
import DdImport from './import/dd/ddimport';
import GPSLImport from './import/dd/gpslimport';
import TemplateImport from './import/dd/templateimport';
import AumImportComponent from './import/dd/aumimport';
import DdAum from './ddaum/ddaum';
import DdAumHeader from './ddaum/ddaumheader';
import AumImportService from '../../services/aumimport';
import checkRoles from '../authentification/checkRoles';
import AdminAuditTrail from './audittrail/adminaudittrail';
import CreateAssetMgr from './create/assetMgr/createassetmgr';
import AddStrategyToAssetMgr from './addstrategytoam';
import AddFundsToStrategy from './addfundstostrategy';
import RelocateStrategyPage from './relocateStrategy';
import AddMandateToStrategy from './addmandatetostrategy';

/* function ImportAdminRouter() : ReactElement {
  const { path } = useRouteMatch();
  return(
    <>
    <div>
      { <Route path={`${path}/dd`} component={DdViewer}/> }
      <Route exact path={`${path}`}>
        <ImportAdminPage />
      </Route>
    </div>
    </>
  );
} */

function ImportRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<Import>>(null);
  const importService = new ImportService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    update && id && importService.getEntity(id)
      .then((apiResp) => {
        setData(apiResp.data);
        setUpdate(false);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
        setUpdate(false);
      });
  }, [update, id]);

  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
            <ImportPage importObj={data} />
          }
          {!data &&
            <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function ImportGroupRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<ImportGroup>>(null);
  const importGroupService = new ImportGroupService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    update && id && importGroupService.getEntity(id)
      .then((apiResp) => {
        setData(apiResp.data);
        setUpdate(false);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
        setUpdate(false);
      });
  }, [update, id]);

  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
          <ImportGroupPage importGroup={data} />
          }
          {!data &&
          <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function DdElementGroupRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<DdElementGroupType>>(null);
  const ddElementGroupsService = new DdElementGroupsService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    setUpdate(true);
  }, [id]);

  useEffect(() => {
    update && id && ddElementGroupsService.getEntity(id)
      .then((apiResp) => {
        setData(apiResp.data);
        setUpdate(false);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
        setUpdate(false);
      });
  }, [update, id]);

  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
          <Grid container spacing={2}>
            <DdElementGroupProvider value={data}>
              <Grid item xs={12}>
                <DdElementGroupHeader ddElementGroup={data} />
              </Grid>
              <Grid item xs={12}>
                <DisplayTabs tabs={ddElementGroupTabs} />
              </Grid>
              <Grid item xs={12}>
                <TabsRouter tabs={ddElementGroupTabs} />
              </Grid>
            </DdElementGroupProvider>
          </Grid>
          }
          {!data &&
          <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function DdTemplatesRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<DdTemplate>>(null);
  const ddElementGroupsService = new DdTemplatesService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    setUpdate(true);
  }, [id]);

  useEffect(() => {
    update && id && ddElementGroupsService.getEntity(id)
      .then((apiResp) => {
        setData(apiResp.data);
        setUpdate(false);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
        setUpdate(false);
      });
  }, [update, id]);

  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
          <Grid container spacing={2}>
            <DdTemplateProvider value={data}>
              <Grid item xs={12}>
                <DdTemplateHeader ddTemplate={data} />
              </Grid>
              <Grid item xs={12}>
                <DisplayTabs tabs={ddTemplateTabs} />
              </Grid>
              <Grid item xs={12}>
                <TabsRouter tabs={ddTemplateTabs} />
              </Grid>
            </DdTemplateProvider>
          </Grid>
          }
          {!data &&
            <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function DdReportBlocksRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<DdReportBlock>>(null);
  const ddReportGroupsService = new DdReportBlocksService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    setUpdate(true);
  }, [id]);

  useEffect(() => {
    update && id && ddReportGroupsService.getEntity(id)
      .then((apiResp) => {
        setData(apiResp.data);
        setUpdate(false);
      })
      .catch((err) => {
        setError(ServiceError.ensureServiceError(err));
        setUpdate(false);
      });
  }, [update, id]);

  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={setUpdate}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
            <Grid container spacing={2}>
              <DdReportBlockProvider value={data}>
                <Grid item xs={12}>
                  <DdReportBlockHeader ddReportBlock={data} />
                </Grid>
                <Grid item xs={12}>
                  <DisplayTabs tabs={ddReportBlockTabs} />
                </Grid>
                <Grid item xs={12}>
                  <TabsRouter tabs={ddReportBlockTabs} />
                </Grid>
              </DdReportBlockProvider>
            </Grid>
          }
          {!data &&
            <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function AumRouter() : ReactElement {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Nullable<AumImport>>(null);
  const ddAumService = new AumImportService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const { keycloak } = useKeycloak();
  const isDevTabAccessibleToUser = checkRoles(['admin', 'developer'], keycloak);

  const retrieveData = () => {
    if (id) {
      ddAumService.getEntity(id)
        .then(apiResp => setData(apiResp.data))
        .catch(err => setError(ServiceError.ensureServiceError(err)));
    }
  };

  useEffect(() => {
    retrieveData();
  }, [id]);
  // pathHook(`${data?.filename}`);

  return (
    <SetUpdateHookContextProvider value={retrieveData}>
      { error ? <ErrorPageManager error={error} /> :
        <>
          { data &&
          <Grid container spacing={2}>
            <DdAumProvider value={data}>
              <Grid item xs={12}>
                <DdAumHeader ddAum={data} />
              </Grid>
              <Grid item xs={12}>
                <DisplayTabs tabs={aumTabs(isDevTabAccessibleToUser)} />
              </Grid>
              <Grid item xs={12}>
                <TabsRouter tabs={aumTabs(isDevTabAccessibleToUser)} />
              </Grid>
            </DdAumProvider>
          </Grid>
          }
          {!data &&
            <div>Loading...</div>
          }
        </>
      }
    </SetUpdateHookContextProvider>
  );
}

function AdminRouter(): ReactElement {

  return (
    <>
      <Routes>
        <Route path='/' element={<AdminPage />} />
        <Route path='/create' element={<Outlet />}>
          <Route path='assetMgr' element={<CreateAssetMgr />} />
          <Route path='addStrategyToAM' element={<></>} />
        </Route>

        <Route path='/addStrategyToAM/*' element={<AddStrategyToAssetMgr />} />
        <Route path='/addFundsToStrategy/*' element={<AddFundsToStrategy />} />
        <Route path='/relocateStrategy/*' element={<RelocateStrategyPage />} />
        <Route path='/addMandateToStrategy/*' element={<AddMandateToStrategy />} />

        <Route path='/import/*' element={<Outlet />}>
          <Route index element={<Navigate to='dd' />} />
          <Route path='dd' element={<DdImport />} />
          <Route path='gpsl' element={<GPSLImport />} />
          <Route path='template' element={<TemplateImport />} />
          <Route path='aum' element={<AumImportComponent />} />
        </Route>
        <Route path='/ddelementgroups/*' element={
          <IdRouter index={<DdElementGroups />}>
            <DdElementGroupRouter />
          </IdRouter>
        }
        />
        <Route path='/ddtemplates/*' element={
          <IdRouter index={<DdTemplates />}>
            <DdTemplatesRouter />
          </IdRouter>
        }
        />
        <Route path='/ddreportblocks/*' element={
          <IdRouter index={<DdReportBlocks />}>
            <DdReportBlocksRouter />
          </IdRouter>
        }
        />
        <Route path='/imports/*' element={
          <IdRouter index={<ImportListingPage />}>
            <ImportRouter />
          </IdRouter>
        }
        />
        <Route path='/importgroups/*' element={
          <IdRouter index={<ImportGroupList />}>
            <ImportGroupRouter />
          </IdRouter>
        }
        />
        <Route path='/aum/*' element={
          <IdRouter index={<DdAum />}>
            <AumRouter />
          </IdRouter>
        }
        />
        <Route path='/audittrail' element={<AdminAuditTrail entityType='dds' id='66603674b903ae33e61bbd6e' />} />
        <Route path='/settings' element={<SettingsPage />} />
      </Routes>
    </>
  );
}

export default AdminRouter;

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useTheme, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { DialogActions, TextField, Grid, Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import { DdService } from '../../services/dds';
import { CustomIconButton } from '../button/button';
import './inputnumberfix.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const styles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    transition: 'all .5s'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[800],
    height: '32px',
    width: '32px',
    lineHeight: '32px',
    fontSize: '16px',
    backgroundColor: theme.palette.grey[200]
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  pageInputOff: {
    border: '1px solid grey',
    width: '24px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    '& hover': {
      border: `1px solid ${  theme.palette.primary.main}`
    }
  },
  pageInputOn: {
    border: `1px solid ${  theme.palette.primary.main}`,
    width: '24px',
    textAlign: 'center',
    borderRadius: '4px'
  }
}));

export interface DialogTitleProps {
  id: string,
  children: React.ReactNode,
  onClose: () => void
}

function DialogTitle(props: DialogTitleProps): ReactElement {
  const { children, onClose, ...other } = props;
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const DialogContent = styled(MuiDialogContent)(() => ({
  '& .MuiDialogContent-root': {
    padding: useTheme().spacing(2)
  }
}));

export interface PDFViewerDialogProps {
  documentId: string,
  ddId: string,
  documentTitle: string,
  handleDownload: (docId: string) => void,
  handleDisplay?: (docId: string) => void
}

function PdfViewerDialog( props: PDFViewerDialogProps ): ReactElement {
  const [open, setOpen] = React.useState(false);
  const ddService = new DdService();
  const url = props.handleDisplay ? props.handleDisplay(props.documentId) : ddService.getDownloadURL(props.ddId, props.documentId);
  const [file] = useState(url);
  const [myNumPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState<boolean>(false);
  const [zoom, setZoom] = useState(false);
  const el = useRef<null | HTMLDivElement>(null);
  const [pageInput, setPageInput] = useState('1');
  const [directInput, setDirectInput] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  console.log(file);

  useEffect(() => {
    if (el.current !== null){
      el.current.scrollTo(0, 0);
    }
    if (myNumPages !== null && pageNumber > myNumPages){
      setPageNumber(myNumPages);
      setPageInput(myNumPages.toString());
    }
    if (myNumPages !== null && pageNumber < 1){
      setPageNumber(1);
      setPageInput('1');
    }
  }, [pageNumber]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDirectInput(false);
    setPageInput('1');
    setPageNumber(1);
    setZoom(false);
  };

  const handleChangePage = () => {
    if (myNumPages !== null){
      if (Number(pageInput) < 1 || Number(pageInput) > myNumPages){
        setPageInput(pageNumber.toString());
      } else {
        setPageNumber(Number(pageInput));
      }
    }
    setDirectInput(false);
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "ArrowUp" && pageNumber >= 0 && myNumPages !== null) {
      setPageNumber(myNumPages);
    }

    if (event.code === "ArrowDown" && pageNumber > 1) {
      setPageNumber(1);
    }

    if (event.code === "ArrowLeft" && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }

    if (event.code === "ArrowRight" && myNumPages !== null && pageNumber < myNumPages) {
      setPageNumber(pageNumber + 1);
    }

    if (event.code === 'Enter'){
      handleChangePage();
    }
  };

  const handlePageAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  return (
    <Box style={{ display: 'inline-block' }} onKeyDown={keyDownHandler}>
      <CustomIconButton variant='text' onClick={handleClickOpen} small>
        <VisibilityRoundedIcon />
      </CustomIconButton>
      <Dialog
        onClose={handleClose} aria-labelledby='pdf-dialog' open={open}
        maxWidth={false}
      >
        <DialogTitle id='pdf-dialog' onClose={handleClose}>
          <Typography variant='h3'>{ props.documentTitle }</Typography>
        </DialogTitle>
        <DialogContent ref={el} style={{ minWidth: '400px', minHeight: '400px' }}>
          <Document
            file={file || undefined}
            onLoadSuccess={({ numPages }) => {
              setPageNumber(1);
              setNumPages(numPages);
              setRenderNavButtons(true);
            }}
          >
            <Page pageNumber={pageNumber} scale={!zoom ? .9 : 1.4} renderTextLayer={false} renderAnnotationLayer={false}/>
          </Document>
        </DialogContent>
        { renderNavButtons &&
					<DialogActions>
					  <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
					    <IconButton
					      color='primary'
					      aria-label='previous page'
					      component='span'
					      onClick={previousPage}
					      disabled={pageNumber <= 1 || directInput}
					      size='large'>
					      <ArrowBackIosRoundedIcon/>
					    </IconButton>
					    <Grid style={{ display: 'flex', alignItems: 'center' }}>
					      {directInput ?
					        <TextField
					          variant='standard'
					          type='number'
					          onChange={handlePageAccess}
					          onBlur={handleChangePage}
					          value={pageInput}
					          autoFocus
					          InputProps={{
					            disableUnderline: true,
					            inputProps: {
					              style: { textAlign: "center" }
					            }
					          }}
					          className={classes.pageInputOn}
					        /> :
					        <Typography
					          onClick={() => {
					            setDirectInput(true);
					            setPageInput(pageNumber.toString());
					          }}
					          className={classes.pageInputOff}
					        >
					          {pageNumber}
					        </Typography>
					      }
					      <Typography style={{ marginLeft: '4px' }}>/ {myNumPages}</Typography>
					    </Grid>
					    <IconButton
					      color='primary'
					      aria-label='next page'
					      component='span'
					      onClick={nextPage}
					      disabled={pageNumber === myNumPages || directInput}
					      size='large'>
					      <ArrowForwardIosRoundedIcon/>
					    </IconButton>
					    <CustomIconButton variant='contained' aria-label='zoom'  small onClick={() => setZoom(!zoom)}>
					      { zoom ? <ZoomOutRoundedIcon /> : <ZoomInRoundedIcon /> }
					    </CustomIconButton>
					    <CustomIconButton variant='contained' onClick={() => props.handleDownload(props.documentId)} small style={{ marginLeft: '16px' }}>
					      <GetAppOutlinedIcon />
					    </CustomIconButton>
					  </Grid>
					</DialogActions>
        }
      </Dialog>
    </Box>
  );
}

export default PdfViewerDialog;

import React, {
  createContext,
  createRef, Dispatch,
  ReactElement,
  useContext, useEffect,
  useState
} from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import {
  Chip,
  Avatar,
  ListItem,
  ListItemText,
  Paper,
  TextareaAutosize,
  Typography,
  Grid, Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DOMPurify from 'dompurify';
import {
  CommentsServiceType,
  DdComments, Nullable
} from '../../../types/types';
import MessageBox from './messagebox';
import { parseISOString } from '../../../utils/parse';
import CustomButton, { CustomIconButton } from '../../button/button';
import getUserFirstAndLastName, { getUserFirstAndLastNameLetter } from '../../../utils/usermanagement';

interface EventsViewProps {
  events: Array<DdComments>,
  commentsService: CommentsServiceType,
  setUpdate: Dispatch<React.SetStateAction<boolean>>,
  readonly?: boolean
}

export const CommentsService = createContext<Nullable<CommentsServiceType>>(null);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '12px'
  },
  msgContainer: {
    marginBottom: '10px'
  },
  msgSubContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative'
  },
  msgBasicLine: {
    width: '2px',
    height: 'calc(100% - 30px)',
    left: '18px',
    top: '44px',
    backgroundColor: '#e0e0e0',
    position: 'absolute'
  },
  btns: {
    position: 'relative',
    marginLeft: '44px',
    paddingTop: '10px'
  },
  msgReplyLine: {
    width: '2px',
    height: 'calc(100% + 14px)',
    left: '18px',
    top: '0px',
    backgroundColor: '#e0e0e0',
    position: 'absolute'
  },
  msgToAvatarLine: {
    width: '28px',
    borderBottom: '2px solid #e0e0e0',
    borderLeft: '2px solid #e0e0e0',
    borderBottomLeftRadius: '12px',
    height: '20px',
    left: '-24px',
    top: '14px',
    backgroundColor: 'transparent',
    position: 'absolute'
  },
  toBtnLine: {
    width: '28px',
    borderBottom: '2px solid #e0e0e0',
    borderLeft: '2px solid #e0e0e0',
    borderBottomLeftRadius: '12px',
    height: '30px',
    left: '-26px',
    top: '14px',
    backgroundColor: 'transparent',
    position: 'absolute'
  },
  root: {
    margin: '0px 0px 0px 14px',
    width: '100%'
  },
  replies: {
    padding: '0px 0px 0px 42px',
    position: 'relative'
  },
  msg: {
    padding: '14px 0px 0px 10px',
    position: 'relative'
  },
  newReply: {
    margin: '0px 0px 0px 10px',
    padding: '12px 8px'
  },
  msgTextArea: {
    padding: '2px 0px 0px 16px'
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  editMsgTextArea: {
    width: '100%',
    outline: 'none',
    border: '0px solid',
    marginTop: '14px',
    marginBottom: '14px',
    padding: '2px 0px 0px 16px'
  },
  msgBtn: {
  },
  newReplyBtn: {
    margin: '14px 0px 0px 6px'
  },
  menu: {
    zIndex: 10
  },
  chip: {
    marginLeft: '10px'
  }
}));

function MessageAndReplies({ event, index, setUpdate, readonly }: { event: DdComments, index: number, setUpdate: Dispatch<React.SetStateAction<boolean>>, readonly?: boolean}): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [comment, setComment] = useState<Nullable<DdComments>>(event);
  const [answerField, setAnswerField] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [commentValue, setCommentValue] = useState(event.content);
  const textAreaRef = createRef<HTMLTextAreaElement>();
  const commentsService = useContext(CommentsService) as CommentsServiceType;
  const { keycloak } = useKeycloak();
  const [userNameLetter, setUserNameLetter] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (comment) {
      getUserFirstAndLastNameLetter(comment.userId)
        .then((letters) => {
          setUserNameLetter(letters);
        });
      getUserFirstAndLastName(comment.userId)
        .then((name) => {
          setUserName(name);
        });
    }
  }, [comment]);

  useEffect(() => {
    setComment(event);
  }, [event]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = textAreaRef.current.value.length;
      textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
    }
  }, [editMode]);

  if (comment === null) {
    return (<></>);
  }

  const addReply = (commentToAdd: DdComments) => {
    comment && setComment({ ...comment, 'replies': [...comment.replies, commentToAdd] });
    setAnswerField(false);
  };

  const handleDelete = () => {
    commentsService.delete(comment._id)
      .then((res) => {
        if (res.data.data._id === comment._id) {
          setComment(null);
          setUpdate(true);
        }
      });
  };

  const handleEdit = () => {
    setEditMode(false);
    commentsService.edit(comment._id, commentValue)
      .then((res) => {
        if (res.data.data._id === comment._id) {
          setComment({ ...comment, 'content': res.data.data.content });
          setUpdate(true);
        }
      });
  };

  return (
    <div className={classes.msg}>
      {index !== -1  && <div className={classes.msgToAvatarLine} />}
      <div className={classes.msgSubContainer}>
        <div className={classes.msgBasicLine} />
        <Avatar className={classes.avatar} alt={`${comment.userId}`} src=''>
          {userNameLetter}
        </Avatar>
        <Paper key={comment._id} elevation={0} variant='outlined' className={classes.root}>
          <ListItem style={{ padding: '4px 14px 0px 14px' }}>
            <ListItemText
              primary={[
                userName,
                comment.type && <Chip key={`${comment._id  }chip`} size='small' label={comment.type} className={classes.chip} />
              ]}
              secondary={parseISOString(comment.timestamp)}
            />
            {comment.userId === keycloak?.tokenParsed?.preferred_username &&
              <>
                {!editMode ?
                  <>
                    <CustomIconButton
                      variant='text'
                      color='primary'
                      onClick={() => setEditMode(true)}
                      small
                      disabled={readonly}
                    >
                      <EditRoundedIcon />
                    </CustomIconButton>
                    <CustomIconButton
                      variant='text'
                      color='secondary'
                      onClick={handleDelete}
                      small
                      disabled={readonly}
                    >
                      <DeleteRoundedIcon />
                    </CustomIconButton>
                  </> :
                  <>
                    <CustomIconButton
                      variant='text'
                      color='primary'
                      small
                      onClick={handleEdit}
                      disabled={readonly}
                    >
                      <CheckRoundedIcon />
                    </CustomIconButton>
                    <CustomIconButton
                      variant='text'
                      color='secondary'
                      small
                      onClick={() => setEditMode(false)}
                      disabled={readonly}
                    >
                      <CloseRoundedIcon />
                    </CustomIconButton>
                  </>
                }
              </>
            }
          </ListItem>
          {!editMode || readonly ?
            <Typography color='textSecondary' variant='h2' gutterBottom className={classes.msgTextArea}>
              {DOMPurify.sanitize(commentValue || '')}
            </Typography> :
            <TextareaAutosize
              ref={textAreaRef}
              className={classes.editMsgTextArea}
              defaultValue={commentValue}
              onChange={(e => setCommentValue(e.target.value))}
              style={{
                fontFamily: 'inherit'
              }}
            />

          }
        </Paper>
      </div>
      {comment.replies.length > 0 &&
        <div className={classes.replies}>
          <div className={classes.msgReplyLine} />
          {comment.replies.map((reply, i) => (
            <MessageAndReplies key={reply._id} event={reply} index={i} setUpdate={setUpdate} readonly={readonly} />
          ))}
        </div>
      }
      <div className={classes.btns}>
        <div className={classes.toBtnLine} />
        {answerField && !readonly ?
          <div className={classes.newReply}>
            <MessageBox id={comment._id} addComment={addReply} setUpdate={setUpdate}>
              <CustomIconButton variant='text' color='secondary' small onClick={() => setAnswerField(false)}>
                <CloseRoundedIcon  />
              </CustomIconButton>
            </MessageBox>
          </div> :
          <>
            <CustomButton variant='text' color='primary' className={classes.newReplyBtn} onClick={() => setAnswerField(true)} disabled={readonly}>
              {t('messages.reply')}
            </CustomButton>
          </>
        }
      </div>
    </div>
  );
}

function Comments(props: EventsViewProps): ReactElement {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [comments, setComments] = useState(props.events);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setComments(props.events);
  }, [props.events]);

  useEffect(() => {
    update && props.commentsService.getAll()
      .then((apiResp) => {
        setComments(apiResp.data.data);
        setUpdate(false);
        props.setUpdate(true);
      })
      .catch(() => {
        setUpdate(false);
        props.setUpdate(true);
      });
  }, [update]);

  const addComment = (comment: DdComments) => {
    setComments([...comments, comment]);
  };

  return (
    <CommentsService.Provider value={props.commentsService}>
      <Grid container spacing={4} className={classes.container}>
        {comments && comments.length > 0 &&
          <Grid item xs={12}>
            {comments.map((event, index) => (
              <div key={event._id} className={classes.msgContainer}>
                <MessageAndReplies event={event} index={index - 1} setUpdate={setUpdate} readonly={props.readonly} />
              </div>
            ))}
          </Grid>
        }
        {!props.readonly &&
          <Grid item xs={12}>
            <MessageBox id={undefined} typeDisp addComment={addComment} setUpdate={setUpdate} />
          </Grid>
        }
      </Grid>
    </CommentsService.Provider>
  );
}

export default Comments;
